<template>
    <div class="modal-wrap">
        <h1 class="tit-h1 d-flex">
            {{ param.headerTitle }}
            <div class="ml-auto align-self-center">
                <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
            </div>
        </h1>
        <div class="modal-box">
            <template v-if="param.flag=='new'">
                <div class="box-ct">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <div class="table-form save-form">
                            <table>
                                <colgroup>
                                    <col width="20%">
                                    <col width="80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            제목
                                        </th>
                                        <td>
                                            <v-text-field 
                                                class="form-inp full table-form-tag" 
                                                outlined 
                                                hide-details
                                                required
                                                v-model="TITLE">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            상세설명
                                        </th>
                                        <td>
                                            <div class="in-value">
                                                <v-textarea
                                                    class="form-inp full"
                                                    height="150px"
                                                    counter
                                                    no-resize
                                                    outlined
                                                    v-model="CONT">
                                                ></v-textarea>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            <v-badge dot color="#EF9191">파일 업로드</v-badge>
                                        </th>
                                        <td>
                                            <v-text-field 
                                                class="form-inp table-form-tag full" 
                                                outlined 
                                                hide-details
                                                type="file"  
                                                id="fileItem"
                                                @input="fnReadFile"
                                                v-model="FILE_ITEM">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-form>
                </div>
            </template>

            <template v-if="param.flag=='modi'">
                <div class="box-ct">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <div class="table-form save-form">
                            <table>
                                <colgroup>
                                    <col width="20%">
                                    <col width="80%">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            제목
                                        </th>
                                        <td>
                                            <v-text-field 
                                                class="form-inp full table-form-tag" 
                                                outlined 
                                                hide-details
                                                required
                                                v-model="TITLE">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            상세설명
                                        </th>
                                        <td>
                                            <div class="in-value">
                                                <v-textarea
                                                    class="form-inp full"
                                                    height="150px"
                                                    counter
                                                    no-resize
                                                    outlined
                                                    v-model="CONT">
                                                </v-textarea>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            현재 파일명
                                        </th>
                                        <td>
                                            {{ UPLD_NAME }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            수정할</br>파일 업로드
                                        </th>
                                        <td>
                                            <v-text-field 
                                                class="form-inp table-form-tag full" 
                                                outlined 
                                                hide-details
                                                type="file"  
                                                id="fileItem"
                                                @input="fnReadFile"
                                                v-model="FILE_ITEM">
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-form>
                </div>
            </template>

            <div class="text-right mt-3">
                <v-btn
                    v-if="!MODI_BOOL"
                    outlined
                    class="btn-point ml-2"
                    id="updateBtn"
                    @click="saveData"
                >
                    저장
                </v-btn>
                <v-btn
                    v-if="MODI_BOOL"
                    outlined
                    class="btn-point ml-2"
                    id="updateBtn"
                    @click="updateData"
                >
                    수정
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios"
import { mixin } from "@/mixin/mixin";
import { mapGetters } from "vuex";

export default {
    name: 'MENU_COP0600',
    components: {},
    mixins:[mixin],
    mapGetters:[mapGetters],
    props : {
        param:{
            type: Object,
        },
    },
    data() {
        return {
            baseUrl:process.env.VUE_APP_API_URL,
            modalData:{},
            valid:true,
            MODI_BOOL: false,

            //v-model
            FILE_ID:'',
            TITLE:'',
            CONT:'',
            FILE_ITEM:'',
            UPLD_ID:'',
            UPLD_GROUP_ID:'',
            DOWN_URL: '',
            UPLD_NAME:'',
            fileSrc:'/file/mng/',

            alertMsg:{
                error      : "시스템 장애가 일어났습니다. 관리자에게 문의하세요.",
                deleteMsg  : "삭제 하시겠습니까?",
                saveMsg    : "저장 하시겠습니까?",
                modiMsg    : "수정 하시겠습니까?",
                saveSuccMsg    : "저장되었습니다.",
                chkFile    : "파일을 선택해주세요.",
                fileMaxMsg    : "이하로 올려주세요.",
            }
        }
    },

    mounted() {
        console.log(this.param)

        if(this.param.flag=='modi') {
            this.MODI_BOOL=true
            this.FILE_ID=this.param.data.FILE_ID
            this.UPLD_ID=this.param.data.UPLD_ID
            this.TITLE=this.param.data.TITLE
            this.CONT=this.decode(this.param.data.CONT)
            this.DOWN_URL=this.param.data.DOWN_URL
            this.UPLD_NAME=this.param.data.UPLD_NAME
        }
    },

    computed: {},

    methods: {

        saveData() {
            if(this.mixin_isEmpty(this.FILE_ITEM)) {
                this.common_alert(this.alertMsg.chkFile)
                return
            }

            this.common_confirm(this.alertMsg.saveMsg, this.uploadFile, null, null, null, 'chk')
        },

        updateData() {
            if(this.FILE_ITEM) {
                this.common_confirm(this.alertMsg.modiMsg, this.uploadFile, null, null, null, 'chk')
            }else{
                let requestData={
                    headers:{}
                    ,sendData:{}
                }
                this.common_confirm(this.alertMsg.modiMsg, this.modiDatas, requestData, null, null, 'chk')
            }
        },

        callNo() {
            this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: false,
            });
        },

        async uploadFile() {
            let requestData = {
                headers: {
                    SERVICE:'files',
                    METHOD:'upload',
                    URL:this.baseUrl+"api/files/upload",
                    AUTHORIZATION:this.mixin_getToken(),
                    UPLOAD_URI:"/api/files/upload",
                    DOWNLOAD_URI:"/api/files/download",
                    DIR:this.fileSrc, //파일 저장 경로
                    TEMP_DIR:this.fileSrc+"/temp",
                    PROC_ID:this.$store.getters['userStore/GE_USER_ROLE'].userId,
                    PATH_TYPE:'file',
                    UPLD_GROUP_ID:this.UPLD_GROUP_ID,
                    'Content-Type':'multipart/form-data',
                },
                sendData:{}
            };

            let vue=this
            let files=vue.$refs.form.inputs;
            let sknObj={}

            const formData = new FormData()
            let relTypeCds=[]
            for (var index = 0; index < files.length; index++) {
                if(files[index].type === 'file' && files[index].$refs.input.files[0] !== undefined){
                    formData.append('fileList', files[index].$refs.input.files[0])

                    sknObj={}
                    sknObj['key']=files[index].$refs.input.files[0].name
                    sknObj['value']="REL_TYPE_CD_02"
                    relTypeCds.push(sknObj)
                }
            }

            formData.append('REL_TABLE_CD', "REL_TABLE_CD_07")
            formData.append('REL_TYPE_CDS', JSON.stringify(relTypeCds))

            axios.post(requestData.headers.URL, formData, {
                headers: requestData.headers
            }).then(response => {
                const returnDatas=response.data
                const retDatas=returnDatas.data
                if(returnDatas.responseMessage==='정상') { //파일 업로드 정상 후 처리

                    for (let index = 0; index < retDatas.length; index++) {
                        const element = retDatas[index];
                        
                        const fileK=element.upldId
                        const fileGroupK=element.upldGroupId

                        //저장 후 파일별 key가 생성됨
                        requestData.sendData["REL_UPLD_ID"]=fileK

                        //그룹키의 경우 신규 저장시 api 서버에서 생성되나 신규생성이 아닌 수정일 경우 기존 그룹키를 가져와서 셋팅해야함
                        requestData.sendData["UPLD_GROUP_ID"]=fileGroupK
                    }
        /////////////////////////////////////////////////////////////////// 파일 업로드 끝...

                    if(!this.MODI_BOOL) {
                        this.registDatas(requestData)
                    }else{
                        this.modiDatas(requestData)
                    }

                }else{
                    return this.common_alert(this.alertMsg.error, "error")
                }
            
            }).catch(error => {
                return error.message
            })
        },

        async registDatas(requestData) {
            this.param.data=[]

            this.modalData={
                TITLE:this.TITLE
                ,CONT:this.CONT
                ,FILE_KEY:requestData.sendData.REL_UPLD_ID
            }

            requestData.headers={
                TYPE: 'BIZ_SERVICE',
                METHOD: 'regist',
                ASYNC: false,
                URL: "/api/hlw/file/mng/regist",
            }

            let userId = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            let contTxt = this.encode(this.CONT)

            requestData.sendData["TITLE"] = this.TITLE;
            requestData.sendData["CONT"] = contTxt;
            requestData.sendData["USER_ID"] = userId;

            let responseGridList = await this.common_postCall(requestData);
            let resHeader = responseGridList.HEADER;
            let resData = responseGridList.DATA;

            // 데이터 조회 오류 시 alert
            if (resHeader.ERROR_FLAG) {
                this.common_alert(this.alertMsg.error, "error");
                return false;
            }else{
                this.param.data.push(this.modalData)
                this.$emit('submit')
            }
        },

        async modiDatas(requestData) {
            requestData.headers={
                TYPE: 'BIZ_SERVICE',
                METHOD: 'regist',
                ASYNC: false,
                URL: "/api/hlw/file/mng/modi",
            }

            let userId = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            let contTxt = this.encode(this.CONT);

            requestData.sendData["FILE_ID"] = this.FILE_ID;
            requestData.sendData["UPLD_ID"] = this.UPLD_ID;
            requestData.sendData["TITLE"] = this.TITLE;
            requestData.sendData["CONT"] = contTxt;
            requestData.sendData["USER_ID"] = userId;

            let responseGridList = await this.common_postCall(requestData);
            let resHeader = responseGridList.HEADER;
            let resData = responseGridList.DATA;

            // 데이터 조회 오류 시 alert
            if (resHeader.ERROR_FLAG) {
                this.common_alert(this.alertMsg.error, "error");
                return false;
            }else{
                this.$emit('submit')
            }
        },

        /**
        * XSS Encode
        */
        encode(strText) {
            if (strText === "" || strText == null) {
                return strText;
            }

            strText = strText.replaceAll("(", "&#40;");
            strText = strText.replaceAll(")", "&#41;");
            strText = strText.replaceAll("[", "&#91;");
            strText = strText.replaceAll("]", "&#93;");
            strText = strText.replaceAll("&", "&amp;");
            strText = strText.replaceAll('"', "&quot;");
            strText = strText.replaceAll("'", "&apos;");
            strText = strText.replaceAll("\r", "<br/>");
            strText = strText.replaceAll("\n", "<p>");
            strText = strText.replaceAll("<", "&lt;");
            strText = strText.replaceAll(">", "&gt;");

            return strText;
        },

        /**
        * XSS Decode
        */
        decode(strText) {

            //값존재유무 체크
            if (strText === "" || strText == null) {
                return strText;
            }
            strText = strText.toString();

            //문자열 길이가 4이상일 경우만 처리
            if (strText.length <= 3) {
                return strText;
            }
            //순서바뀌면 안나옴
            strText = strText.replaceAll("&lt;", "<");
            strText = strText.replaceAll("&gt;", ">");
            strText = strText.replaceAll("&amp;", "&");
            strText = strText.replaceAll("&quot;", '"');
            strText = strText.replaceAll("&apos;", "'");
            strText = strText.replaceAll("<br/>", "\r");
            strText = strText.replaceAll("<p>", "\n");
            strText = strText.replaceAll("&#91;", "[");
            strText = strText.replaceAll("&#93;", "]");
            strText = strText.replaceAll("&#40;", "(");
            strText = strText.replaceAll("&#41;", ")");

            return strText;
        },

        fnReadFile() {
            const updateBtnObj=$('#updateBtn')
            const fileObj=document.getElementById('fileItem')
            if (!this.mixin_isEmpty(fileObj.files)) {
                const file=fileObj.files[0]
                const fileSize=file.size

                const byteUnits=["MB"];
                const numUnit=1024**2;
                let size=fileSize
                let maxSize=this.param.fileMaxSize.replaceAll('MB', '');

                for (let i = 0; i < byteUnits.length; i++) {
                    size=Math.floor(size / numUnit).toFixed(2);
                    console.log(size+byteUnits[i]);
                    if(Number(maxSize) < Number(size)) {
                        this.common_alert('용량초과 : '+maxSize+byteUnits[i]+' '+this.alertMsg.fileMaxMsg, 'error')
                        setTimeout(() => this.FILE_ITEM='', 777);
                    }
                }
            }
        },

    },
}

</script>

<style>
</style>